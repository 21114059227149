import React from 'react';
import './App.css';



class Redirect extends React.Component { 

  constructor() {
    super();
    this.state = { 
     
    };
    
  }

  componentDidMount()
  {
    
  }

  

  
  render()
  {  
    //window.location.replace("/")
    return (
      <div className="pageContainer">
      <embed src="https://www.fallonzirpoli.com" style={{width:'500px', height: '300px'}}></embed>
    </div>

    )
    
    
          
      
  }
}
export default Redirect;
