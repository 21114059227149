import React from 'react';
import './App.css';
import { PopupButton } from "react-calendly";


class App extends React.Component { 

  constructor() {
    super();
    this.state = { 
     
    };
    
  }

  componentDidMount()
  {
    
  }

  

  
  render()
  {  

    console.log(document.getElementsByTagName("button")[0])
    return (
      <div className="pageContainer">
      <center></center>
      <br></br>
    

      <div>

      <center>
        <h1 className="name" style={{marginTop: '.5em'}}>What a Damn Site.</h1>
        <span className="text">modern website development
        <br></br>
  <br></br>

<div className="block">
          May 7th 2024,
<br></br>
<br></br>
I started 'What a Damn Site.' when I was 21 and a little lost creatively 
<br></br>
<br></br>
This project gave me an outlet for so long and I love the places that it has taken me 
<br></br>
<br></br>
I’m ready to lean into my next age of curiosity
<br></br>
<br></br>
Thank you to everyone who gave me an opportunity to build something with them
<br></br>
<br></br>
Below are some of the works I’m most proud of:
</div>
        <br></br>
          <br></br>
        
        
        

        <div className='flex-container'>


        


        <div className='item'>
          {/*<a target="_blank" rel="noreferrer" href="https://dianamilena35mm.com">dianamilena35mm.com</a>*/}
            <a target="_blank" rel="noreferrer" href="https://dianamilena35mm.com" style={{textDecoration: 'none'}}><img src="https://whatadamnsitecdn.b-cdn.net/dianamil-01%20(1).png" className='websiteImage'/>
            <h5 style={{marginTop: '0em', marginBottom: '0em'}}>Diana Alvarez<br></br><i>photographer/events planner</i></h5></a>
            
            
          </div>
          

          <div className='item'>
          {/*<a target="_blank" rel="noreferrer" href="https://dianamilena35mm.com">dianamilena35mm.com</a>*/}
            <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1UinJGyP6SOGpT1Pe7BY64dCNCPl6zAFq/view?usp=share_link" style={{textDecoration: 'none'}}><img src="https://whatadamnsitecdn.b-cdn.net/IMG_4157.jpg" className='websiteImage'/>
            <h5 style={{marginTop: '0em', marginBottom: '0em'}}>ghostboy<br></br><i>augmented reality application</i></h5></a>
            
            
          </div>

          <div className='item'>
          {/*<a target="_blank" rel="noreferrer" href="https://dianamilena35mm.com">dianamilena35mm.com</a>*/}
            <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1FotdsIdRXdRRE6b5i1gs0aaYFM6aSHzo/view?usp=share_link" style={{textDecoration: 'none'}}><img src="https://whatadamnsitecdn.b-cdn.net/IMG_4158.jpg" className='websiteImage'/>
            <h5 style={{marginTop: '0em', marginBottom: '0em'}}>mems<br></br><i>augmented reality tour</i></h5></a>
            
            
          </div>

          <div className='item'>
            <a target="_blank" rel="noreferrer" href="https://www.memscatcher.com" style={{textDecoration: 'none'}}><img src="https://whatadamnsitecdn.b-cdn.net/Group%206.png" className='websiteImage'/>
            {/*<a target="_blank" rel="noreferrer" href="https://memnfts.com">memnfts.com</a>*/}
            <h5 style={{marginTop: '0em', marginBottom: '0em'}} onClick={e => {}}>mems catcher<br></br><i>a tokengated game for mems holders</i></h5></a>
          </div>


         

         


          <div className='item'>
            <a target="_blank" rel="noreferrer" href="https://fanfundr.com" style={{textDecoration: 'none'}}><img src="https://whatadamnsitecdn.b-cdn.net/IMG_8364.jpg" className='websiteImage'/>
           {/* <a target="_blank" rel="noreferrer" href="https://fanfundr.com">fanfundr.com</a>*/}
            <h5 style={{marginTop: '0em', marginBottom: '0em'}}>FANFUNDR<br></br><i>web3 start-up</i></h5></a>
          </div>

         



          <div className='item'>
            <a target="_blank" rel="noreferrer" href="https://themellowyellow.net" style={{textDecoration: 'none'}}><img src="https://whatadamnsitecdn.b-cdn.net/IMG_4810%20(1).jpeg" className='websiteImage'/>
           {/* <a target="_blank" rel="noreferrer" href="https://themellowyellow.net">themellowyellow.net</a>*/}
           <h5 style={{marginTop: '0em', marginBottom: '0em'}}>The Mellow Yellow<br></br><i>album</i></h5></a>
          </div>

          <div className='item'>
            <a target="_blank" rel="noreferrer" href="https://calibucky.netlify.com" style={{textDecoration: 'none'}}><img src="https://whatadamnsitecdn.b-cdn.net/metaimage%20(1).jpeg" className='websiteImage'/>
            {/*<a target="_blank" rel="noreferrer" href="https://calibucky.netlify.com">calibucky.netlify.app</a>*/}
            <h5 style={{marginTop: '0em', marginBottom: '0em'}}>CaliBucky<br></br><i>musician</i></h5></a>
          </div>

          
          <div className='item'>
            <a target="_blank" rel="noreferrer" href="https://fdpo.net" style={{textDecoration: 'none'}}><img src="https://whatadamnsitecdn.b-cdn.net/FDPO_OFFICIAL_COVER-01.png" className='websiteImage'/>
            {/*<a target="_blank" rel="noreferrer" href="https://fdpo.net">fdpo.net</a>*/}
            <h5 style={{marginTop: '0em', marginBottom: '0em'}}>For Demonstration Purposes Only<br></br><i>album</i></h5></a>
          </div>

          <div className='item'>
            <a target="_blank" rel="noreferrer" href="https://chasingnick.com" style={{textDecoration: 'none'}}><img src="https://whatadamnsitecdn.b-cdn.net/IMG_1564.jpeg" className='websiteImage'/>
            {/*<a target="_blank" rel="noreferrer" href="https://chasingnick.com">chasingnick.com</a>*/}
            <h5 style={{marginTop: '0em', marginBottom: '0em'}}>Nick Chase<br></br><i>musician</i></h5></a>
          </div>

         
          
          {/*<div className='item'>
            <a target="_blank" rel="noreferrer" href="https://shop.memnfts.com" style={{textDecoration: 'none'}}><img src="https://whatadamnsitecdn.b-cdn.net/IMG_8362%20(1).jpeg" className='websiteImage'/>
            {/*<a target="_blank" rel="noreferrer" href="https://shop.memnfts.com">shop.memnfts.com</a>
            <h5 style={{marginTop: '0em', marginBottom: '0em'}}>mems shop<br></br><i>mems' tokengated shop</i></h5></a>
    </div>*/}


       
          
          <div className='item'>
            <a target="_blank" rel="noreferrer" href="https://badpathmedia.com" style={{textDecoration: 'none'}}><img src="https://whatadamnsitecdn.b-cdn.net/badpath-01.png" className='websiteImage'/>
            {/*<a target="_blank" rel="noreferrer" href="https://badpathmedia.com">badpathmedia.com</a>*/}
            <h5 style={{marginTop: '0em', marginBottom: '0em'}}>Bad Path Media<br></br><i>music industry</i></h5></a>
          </div>



         



          
         

          

         
          
         
          
         
         

          
        </div>

       {/* <br></br>
        <a target="_blank" rel="noreferrer" href="https://dianamilena35mm.com">dianamilena35mm.com</a>
        <br></br>
        <a target="_blank" rel="noreferrer" href="https://fanfundr.com">fanfundr.com</a>
        <br></br>
        <a target="_blank" rel="noreferrer" href="https://memscatcher.com">memscatcher.com</a>
        <br></br>
        <a target="_blank" rel="noreferrer" href="https://calibucky.netlify.app">calibucky.netlify.app</a>
        <br></br>
        <a target="_blank" rel="noreferrer" href="https://memsnyc.com">memsnyc.com</a>
        <br></br>
        <a target="_blank" rel="noreferrer" href="https://lollamems.com">lollamems.com</a>
        <br></br>
        <a target="_blank" rel="noreferrer" href="https://shop.memnfts.com">shop.memnfts.com</a>
        <br></br>
        <a target="_blank" rel="noreferrer" href="https://memsvr.netlify.com">memsvr.netlify.com</a>
        <br></br>
        <a target="_blank" rel="noreferrer" href="https://orlandokenny.com">orlandokenny.com</a>
      <br></br>
        <a target="_blank" rel="noreferrer" href="https://themellowyellow.net">themellowyellow.net</a>
      <br></br>
      <a target="_blank" rel="noreferrer" href="https://badpathmedia.com">badpathmedia.com</a>
    <br></br>*/}
      {/*<a target="_blank" rel="noreferrer" href="https://lehrockpeople.com">lehrockpeople.com</a>
      <br></br>
      <a target="_blank" rel="noreferrer" href="https://andrewstrickland.com">andrewstrickland.com</a>
      <br></br>
      <a target="_blank" rel="noreferrer" href="https://fruitioncreative.net">fruitioncreative.net</a>
      <br></br>
      {/*<a target="_blank" rel="noreferrer" href="https://tribleapp.com">tribleapp.com</a>
      <br></br>
      <a target="_blank" rel="noreferrer" href="https://fdpo.net">fdpo.net</a>
      <br></br>
      <a target="_blank" rel="noreferrer" href="https://chasingnick.com">chasingnick.com</a>
      <br></br>
      <a target="_blank" rel="noreferrer" href="https://zlisterandfriends.com">zlisterandfriends.com</a>
  <br></br>*/}
        


        </span>


      

      </center>
 </div>

      <div className="footer">
        <h1>all rights reserved.</h1>
        
      </div>
      
    </div>

    )
    
    
          
      
  }
}
export default App;
